import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NotAuthorizedAccess = ({ reason }) => (
  <section className='notAuthorizedAccess'>
    <h1>Vous êtes sur une page de redirection, vous souhaitez accéder à la page souhaitée ?</h1>
    <h2>
      {reason}
    </h2>
    <Link to="/">Retour</Link>
  </section>
);
NotAuthorizedAccess.propTypes = {
  reason: PropTypes.string.isRequired,
};

export default NotAuthorizedAccess;

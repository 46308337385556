import { Card } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const CardTemplate = ({ title, children, className }) => (
  <Card
    className={className}
    title={title}
  >
    {children}
  </Card>
);

CardTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default CardTemplate;

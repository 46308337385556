import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import ChartContainer from './ChartContainer';

const BarChartTemplate = ({ 
  data, 
  dataKey, 
  title, 
  fill,
  width,
  height,
}) => (
  <ChartContainer title={title}>
    <BarChart  width={width} height={height} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Bar dataKey={dataKey} fill={fill} />
    </BarChart>
  </ChartContainer>
);

BarChartTemplate.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired, 
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export default BarChartTemplate;
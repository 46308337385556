import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import { blackColor } from '../../../assets/styles/colors';
import ChartContainer from './ChartContainer';

const AreaChartFillByValue = ({ 
  data,
  dataKey,
  title,
  width,
  height,
  stop,
}) => (
  <ChartContainer title={title}>
    <AreaChart width={width} height={height} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey={dataKey} stroke={blackColor} fill="url(#splitColor)" />
      <defs>
        <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
          {
            stop.map((item, index) => (
              <stop  
                key={index} 
                offset={item.offset} 
                stopColor={item.stopColor} 
                stopOpacity={item.stopOpacity} />
            ))
          }
        </linearGradient>
      </defs>
      <Area type="monotone" stroke={blackColor} fill="url(#splitColor)" />
    </AreaChart>
  </ChartContainer>
);

AreaChartFillByValue.propTypes = {
  stop: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired, 
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AreaChartFillByValue;
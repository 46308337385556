import { bluePrimaryColor, greenLightColor, greenLightColor2, orangeColor, redColor } from '../../assets/styles/colors';
import AreaChartFillByValue from '../shared/charts/AreaChartFillByValue';
import BarChartTemplate from '../shared/charts/BarChartTemplate';
import ScatterChartTemplate from '../shared/charts/ScatterChartTemplate';


export const baseLineCharts = [
  { 
    title: 'Températures', 
    lines: [{ dataKey: 'temperature', strokeColor: orangeColor }], 
  },
  { 
    title: 'Tensions',
    lines: [{ dataKey: 'vbatt', strokeColor: greenLightColor2 }],
  },
  { 
    title: 'Status des capteurs',
    yAxisDomain: [-30, 60], 
  },
];

export const charts = [
  {
    component: BarChartTemplate,
    dataKey: 'humidity',
    title: 'Humidité',
    width: 300,
    height: 200,
    fill: bluePrimaryColor,
  },
  {
    component: ScatterChartTemplate,
    dataKey: 'materials',
    title: 'Matériaux',
    width: 300,
    height: 200,
    scatters: [
      { name: 'état du composant flash ', dataKey: 'flashStatus', fill: greenLightColor },
      { name: 'état du composant capteur ', dataKey: 'iicSensorStatus', fill: redColor },
      { name: 'état du composant SD card ', dataKey: 'sdStatus', fill: orangeColor },
    ],
  },
  {
    component: AreaChartFillByValue,
    title: 'db',
    dataKey: 'rssi',
    width: 300,
    height: 200,
    stop: [
      { offset: '0%', stopColor: greenLightColor, stopOpacity: 1 },
      { offset: '100%', stopColor: redColor, stopOpacity: 1 },
    ],
  },
];
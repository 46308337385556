export const initialState = {
  auth: {
    token: false,
  },
  soundRecord: {
    data: null,
    loading: false,
    error: null,
  },
  soundRecordById: {
    data: null,
    loading: false,
    error: null,
  },
  soundRecordBySerial: {
    data: null,
    loading: false,
    error: null,
  },
  soundRecordByRangeAndSerial: {
    data: null,
    loading: false,
    error: null,
  },
  soundRecordByRange: {
    data: null,
    loading: false,
    error: null,
  },
};
  
export const reducer = (state, action) => {
  switch (action.type) {
  case 'LOGIN_SUCCESS':
    return {
      ...state,
      auth: { token: true },
    };
  case 'FETCH_SOUND_RECORD_DATA_START':
    return {
      ...state,
      soundRecord: { ...state.soundRecord, loading: true, error: null },
    };
  case 'FETCH_SOUND_RECORD_DATA_SUCCESS':
    return {
      ...state,
      soundRecord: { ...state.soundRecord, loading: false, data: action.payload },
    };
  case 'FETCH_SOUND_RECORD_DATA_ERROR':
    return {
      ...state,
      soundRecord: { ...state.soundRecord, loading: false, error: action.payload },
    };
  
  case 'FETCH_SOUND_RECORD_DATA_BY_ID_START':
    return {
      ...state,
      soundRecordById: { ...state.soundRecordById, loading: true, error: null },
    };
  case 'FETCH_SOUND_RECORD_DATA_BY_ID_SUCCESS':
    return {
      ...state,
      soundRecordById: { ...state.soundRecordById, loading: false, data: action.payload },
    };
  case 'FETCH_SOUND_RECORD_DATA_BY_ID_ERROR':
    return {
      ...state,
      soundRecordById: { ...state.soundRecordById, loading: false, error: action.payload },
    };
  
  case 'FETCH_SOUND_RECORD_DATA_BY_SERIAL_START':
    return {
      ...state,
      soundRecordBySerial: { ...state.soundRecordBySerial, loading: true, error: null },
    };
  case 'FETCH_SOUND_RECORD_DATA_BY_SERIAL_SUCCESS':
    return {
      ...state,
      soundRecordBySerial: { ...state.soundRecordBySerial, loading: false, data: action.payload },
    };
  case 'FETCH_SOUND_RECORD_DATA_BY_SERIAL_ERROR':
    return {
      ...state,
      soundRecordBySerial: { ...state.soundRecordBySerial, loading: false, error: action.payload },
    };
  
  case 'FETCH_SOUND_RECORD_DATA_BY_RANGE_AND_SERIAL_START':
    return {
      ...state,
      soundRecordByRangeAndSerial: { ...state.soundRecordByRangeAndSerial, loading: true, error: null },
    };
  case 'FETCH_SOUND_RECORD_DATA_BY_RANGE_AND_SERIAL_SUCCESS':
    return {
      ...state,
      soundRecordByRangeAndSerial: { ...state.soundRecordByRangeAndSerial, loading: false, data: action.payload },
    };
  case 'FETCH_SOUND_RECORD_DATA_BY_RANGE_AND_SERIAL_ERROR':
    return {
      ...state,
      soundRecordByRangeAndSerial: { ...state.soundRecordByRangeAndSerial, loading: false, error: action.payload },
    };
  
  case 'FETCH_SOUND_RECORD_DATA_BY_RANGE_START':
    return {
      ...state,
      soundRecordByRange: { ...state.soundRecordByRange, loading: true, error: null },
    };
  case 'FETCH_SOUND_RECORD_DATA_BY_RANGE_SUCCESS':
    return {
      ...state,
      soundRecordByRange: { ...state.soundRecordByRange, loading: false, data: action.payload },
    };
  case 'FETCH_SOUND_RECORD_DATA_BY_RANGE_ERROR':
    return {
      ...state,
      soundRecordByRange: { ...state.soundRecordByRange, loading: false, error: action.payload },
    };
  
  default:
    return state;
  }
};
import React from 'react';
import { PropTypes } from 'prop-types';
import { Table } from 'antd';

const TableTemplate = ({
  columns, 
  dataSource, 
  pagination = false,
  title, 
  className,
}) => (
  <Table
    className={className}
    columns={columns}
    dataSource={dataSource}
    size="small"
    bordered
    scroll={{ y: 250 }}
    pagination={pagination}
    title={() => <h4>{title}</h4>}
  />
);

TableTemplate.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.bool,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TableTemplate;

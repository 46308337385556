import axios from 'axios';

const token = process.env.REACT_APP_API_TOKEN;

const get = async ({ dispatch, type, apiUrl }) => {
  dispatch({type: `FETCH_${type}_START`});
  try {
    const response = await axios.get(`${apiUrl}`, {
      headers: {
        token,
      },
    });
    if (response.status === 204) {
      dispatch({type: `FETCH_${type}_SUCCESS`, payload: response.status});
    } else if (response.status === 200) {
      dispatch({type: `FETCH_${type}_SUCCESS`, payload: response.data});
    } else {
      dispatch({type: `FETCH_${type}_SUCCESS`, payload: response.status});
    }
  } catch (err) {
    dispatch({type: `FETCH_${type}_ERROR`, payload: err.message});
  }
};

export default { get };
import React from 'react';
import PropTypes from 'prop-types';
import TableTemplate from '../../antd/TableTemplate';

const SoundRecordDataList = ({ title, dataSource, columns }) => (
  <TableTemplate 
    title={title} 
    columns={columns} 
    dataSource={dataSource}
  />
);

SoundRecordDataList.propTypes = {
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SoundRecordDataList;
import { useNavigate } from 'react-router-dom';
import FormTemplate from '../antd/FormTemplate';
import { regexEmail, regexMDP, showNotification } from '../../utils/utils';
import { useAppContext } from '../../utils/contexts/AppContext';
import { login } from '../../utils/services';
import { LockOutlined, EyeTwoTone } from '@ant-design/icons';

const EMAIL = process.env.REACT_APP_EMAIL;
const PASSWORD = process.env.REACT_APP_PASSWORD;

const LoginForm = () => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    const { email, password } = values;
    if (email === EMAIL && password === PASSWORD) {
      login({ dispatch });
      showNotification('success', 'Connexion réussie', 'Vous êtes maintenant connecté.');
      navigate('/dashboard');
    } else {
      showNotification('error', 'Erreur de connexion', 'Email ou mot de passe invalide.');
    }
  };

  const fields = [
    { 
      label: 'Email',
      name: 'email',
      placeholder: 'email@address.com',
      rules: [{
        required: true,
        pattern: regexEmail,
        message: 'Veuillez entrer un email valide.',
      }],
    },
    { 
      label: 'Mot de passe',
      name: 'password',
      placeholder: 'mot de passe',
      prefix: <LockOutlined />,
      sufix: <EyeTwoTone />,
      type: 'password',
      help: 'Votre mot de passe doit contenir au moins 8 caractères dont 1 chiffre, une majuscule, un caractère spécial et une minuscule.',
      rules: [{
        required: true,
        pattern: regexMDP,
        message: 'Veuillez entrer un mot de passe valide.',
      }],
    },
  ];

  return (
    <FormTemplate 
      className="loginForm"
      formName="login" 
      fields={fields} 
      onFinish={handleFinish}
      layout='vertical'
    />
  );
};

export default LoginForm;
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import ChartContainer from './ChartContainer';

const LineChartTemplate = ({ 
  data,
  lines,
  title,
  yAxisDomain,
  width,
  height,
}) => (
  <ChartContainer title={title}>
    <LineChart width={width} height={height} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis domain={yAxisDomain} />
      <Tooltip />
      {lines.map((line, index) => (
        <Line
          key={index}
          type="monotone"
          dataKey={line.dataKey}
          stroke={line.strokeColor}
        />
      ))}
    </LineChart>
  </ChartContainer>
);

LineChartTemplate.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    strokeColor: PropTypes.string.isRequired,
    dot: PropTypes.bool,
    isAnimationActive: PropTypes.bool,
    strokeDasharray: PropTypes.string,
    activeDot: PropTypes.bool,
    connectNulls: PropTypes.bool,
  })).isRequired,
  title: PropTypes.string.isRequired,
  yAxisDomain: PropTypes.array,
};

export default LineChartTemplate;
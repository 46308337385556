import React, { useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import PropTypes from 'prop-types';
import ChartContainer from './ChartContainer';

const ScatterChartTemplate = ({ data, title, width, height, scatters }) => {
  const [activeScatter, setActiveScatter] = useState(null);
  return (
    <ChartContainer title={title}>
      <ScatterChart width={width} height={height} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend 
          onMouseEnter={(key) => {
            const { dataKey } = key;
            setActiveScatter(dataKey);
          }}
        />
        {scatters?.map((scatter, index) => (
          <Scatter
            key={index}
            name={scatter.name} 
            dataKey={scatter.dataKey} 
            fill={scatter.fill} 
            opacity={activeScatter === scatter.dataKey ? 1 : 0.3}
          />
        ))}
      </ScatterChart>
    </ChartContainer>
  );
};

ScatterChartTemplate.propTypes = {
  scatters: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired, 
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ScatterChartTemplate;
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Select, DatePicker } from 'antd';

const FormTemplate = ({ formName, fields, onFinish, layout, className }) => {
  const { Item } = Form;
  const { RangePicker } = DatePicker;
  const { Password } = Input;

  const renderField = (field) => {
    switch (field.type) {
    case 'select':
      return (
        <Select
          placeholder={field.placeholder}
          options={field.options}
        />
      );
    case 'rangeDate':
      return <RangePicker />;
    case 'password':
      return (
        <Password
          prefix={field.prefix}
          suffix={field.sufix}
          placeholder={field.placeholder}
        />
      );
    default:
      return <Input placeholder={field.placeholder} />;
    }
  };

  return (
    <Form
      className={className}
      name={formName}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      layout={layout}
    >
      {fields?.map((field, index) => (
        <Item
          key={index}
          label={field.label}
          name={field.name}
          help={field.help}
          rules={field.rules}
        >
          {renderField(field)}
        </Item>
      ))}
      <Item className={layout === 'vertical' ? 'form-submit-vertical' : ''}>
        <Button type="primary" htmlType="submit">
          valider
        </Button>
      </Item>
    </Form>
  );
};

FormTemplate.propTypes = {
  formName: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      rules: PropTypes.arrayOf(PropTypes.object),
      type: PropTypes.string,
    }),
  ).isRequired,
  onFinish: PropTypes.func.isRequired,
  layout: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FormTemplate;
import { createElement } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { AppstoreOutlined, FileTextOutlined } from '@ant-design/icons';
import logo from '../../assets/images/leakAnalyzer-logo.png';

const Aside = () => {
  const { Sider } = Layout;

  const routesItem = [
    { key: 5, path: '/dashboard', label: 'Tableau de bord', icon: AppstoreOutlined },
    { key: 6, path: '/details', label: 'Details', icon: FileTextOutlined },
  ];

  const items = routesItem.map((route) => ({
    key: route.key,
    icon: createElement(route.icon),
    label: <Link to={route.path}>{route.label}</Link>,
  }));

  return (
    <Sider>
      <img className="aside-logo" src={logo} alt="leak-analyzer-logo" />
      <Menu mode="inline" defaultSelectedKeys={['5']} items={items} />
    </Sider>
  );
};

export default Aside;

import React from 'react';
import { PropTypes } from 'prop-types';
import { Layout } from 'antd';

const { Content } = Layout;

const PublicRoute = ({ children }) => {
  const renderLayout = (
    <Content>
      {children}
    </Content>
  );
  return renderLayout;
};

PublicRoute.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default PublicRoute;

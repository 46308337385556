import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';

const DrawerTemplate = ({
  children,
  width = 500,
  title,
  setCurrentState,
  open = false,
  placement = 'right',
  height = 400,
}) => {
  const handleCloseDrawer = () => {
    setCurrentState(null);
  };
  return (
    <Drawer
      placement={placement}
      title={title}
      width={width}
      height={height}
      open={open}
      onClose={handleCloseDrawer}
      destroyOnClose
    >
      {children}
    </Drawer>
  );
};

DrawerTemplate.propTypes = {
  height: PropTypes.number,
  children: PropTypes.node,
  width: PropTypes.number,
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  setCurrentState: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default DrawerTemplate;

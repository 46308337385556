import React from 'react';
import DrawerTemplate from '../../../antd/DrawerTemplate';
import PropTypes from 'prop-types';
import { useAppContext } from '../../../../utils/contexts/AppContext';
import CardsInfo from './cardsInfo';

const SoundRecordInfosDrawer = ({ 
  openDrawer,
  setOpenDrawer,
}) => {
  const { state } = useAppContext();
  const { data: currentSoundRecordInfos } = state.soundRecordById;
  const serialCurrentInfo = currentSoundRecordInfos?.serial;
  const drawerTitle = `Informations enregistrement serial : ${serialCurrentInfo}`;
  
  return (
    <DrawerTemplate
      title={drawerTitle}
      open={openDrawer}
      placement='right'
      setCurrentState={setOpenDrawer}
      width={700}
    >
      { currentSoundRecordInfos?.data &&
      <CardsInfo currentSoundRecordInfos={currentSoundRecordInfos} />
      }
    </DrawerTemplate>
  );
};

SoundRecordInfosDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func.isRequired,
  soundRecordCurrentInfo: PropTypes.shape({}),
};

export default SoundRecordInfosDrawer;

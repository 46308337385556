import React, { createContext, useContext, useReducer } from 'react';
import { initialState, reducer } from '../reducer';
import PropTypes from 'prop-types';
 
const AppContext = createContext();
 
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
 
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
 
AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
 
const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
 
export { AppProvider, useAppContext };
import React, { useEffect, useState } from 'react';
import SkeletonList from '../../shared/skeletons/SkeletonList';
import { fetchSoundRecordData } from '../../../utils/services';
import { useAppContext } from '../../../utils/contexts/AppContext';
import SoundRecordDataList from '../../shared/lists/SoundRecordDataList';
import { getSoundDataRecordColumns, soundRecordDataSource } from '../../../utils/utils';
import SoundRecordInfosDrawer from './soundRecordInfosDrawer';

const SoundRecordData = () => {
  const { state, dispatch } = useAppContext();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { data: soundRecordData, loading: soundRecordLoading } = state.soundRecord;
  const { data: currentSoundRecordInfos } = state.soundRecordById;

  const hasRecordData = soundRecordData?.length > 0 && !soundRecordLoading;

  const dataSource = soundRecordDataSource(soundRecordData);
  const columns = getSoundDataRecordColumns(dispatch, setOpenDrawer);

  useEffect(() => {
    fetchSoundRecordData({ dispatch });
  }, [dispatch]);

  return (
    <>
      {hasRecordData &&
      <SoundRecordDataList
        title='Liste des enregistrements'
        columns={columns}
        dataSource={dataSource}
      />}
      {soundRecordLoading && <SkeletonList />}

      <SoundRecordInfosDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        soundRecordCurrentInfos={currentSoundRecordInfos}
      />
    </>
  );
};

export default SoundRecordData;

import React from 'react';
import { PropTypes } from 'prop-types';
import { Layout } from 'antd';
import Aside from '../antd/Aside';
import { useAppContext } from '../../utils/contexts/AppContext';
import NotAuthorizedAccess from '../shared/NotAccessAuthorize';

const { Content, Header } = Layout;

const PrivateRoute = ({ children, title = '' }) => {
  const { state } = useAppContext();
  const { token } = state.auth;
  const reason = 'Vous devez vous authentifier pour accéder à cette page';

  const renderLayout = (
    <>
      <Aside />
      <Layout>
        <Header>{title}</Header>
        <Content>
          {children}
        </Content>
      </Layout>
    </>
  );
  return token ? renderLayout : <NotAuthorizedAccess reason={reason} />;
};

PrivateRoute.propTypes = {
  children: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
};

export default PrivateRoute;

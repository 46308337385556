import FormTemplate from '../antd/FormTemplate';
import { dayMonthYearFormat, generateSelectOptions, getUniqueList } from '../../utils/utils';
import { useAppContext } from '../../utils/contexts/AppContext';
import dayjs from 'dayjs';
import { fetchSoundRecordDataByRangeAndSerial } from '../../utils/services';
import PropTypes from 'prop-types';

const FormDetails = ({ setCurrentRangeDate }) => {
  const { state, dispatch } = useAppContext();
  const { data } = state.soundRecord;
  const uniqueSerials = getUniqueList(data);
  const serialOptions = generateSelectOptions(uniqueSerials);
 
  const handleFinish = (values) => {
    const { serial, period } = values;
    const [startDate, endDate] = period;
    const startTimestamp = dayjs(startDate).startOf('day').valueOf();
    const endTimestamp = dayjs(endDate).endOf('day').valueOf();
    const startFormatted = dayjs(startDate).format(dayMonthYearFormat);
    const endFormatted = dayjs(endDate).format(dayMonthYearFormat);

    setCurrentRangeDate({ startDate: startFormatted, endDate: endFormatted });

    fetchSoundRecordDataByRangeAndSerial({ 
      dispatch,
      startDate: startTimestamp, 
      endDate: endTimestamp, 
      serial,
    });
  };

  const fields = [
    { 
      label: 'Serial',
      name: 'serial',
      type: 'select',
      options: serialOptions,
      placeholder: 'Sélectionner un serial',
      rules: [{
        required: true,
        message: 'Veuillez sélectionner un serial',
      }],
    },
    { 
      label: 'Période',
      name: 'period',
      type: 'rangeDate',
      placeholder: 'Sélectionner un serial',
      rules: [{
        required: true,
        message: 'Veuillez sélectionner une période',
      }],
    },
  ];

  return (
    <FormTemplate
      className="mb-large"
      formName="detailsForm"
      fields={fields} 
      onFinish={handleFinish}
      layout='inline'
    />
  );
};

FormDetails.propTypes = {
  setCurrentRangeDate: PropTypes.func.isRequired,
};

export default FormDetails;
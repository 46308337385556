import queries from './queries';
import Dashboard from '../components/dashboard';
import Details from '../components/details';
import Login from '../components/login';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import dayjs from 'dayjs';
import { fetchSoundRecordDataById } from './services';

export const regexEmail = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
export const regexMDP = /^(?=.*[~@!%=+\-*?&[\]{}()'\\|`_^])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

export const dayMonthYearTimeFormat = 'DD/MM/YY HH:mm';
export const dayMonthYearFormat = 'DD/MM/YY';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const fetchData = ({ dispatch, endpoint, type }) => {
  const apiUrl = `${BASE_API_URL}/${endpoint}`;
  return queries.get({ dispatch, type, apiUrl });
};

export const routes = [
  {
    id: 10,
    exact: true,
    path: '/',
    isPublic: true,
    Component: Login,
  },
  {
    id: 20,
    exact: true,
    path: '/dashboard',
    title: 'Tableau de bord',
    Component: Dashboard,
  },
  {
    id: 30,
    exact: true,
    path: '/details',
    title: 'Details',
    Component: Details,
  },
];

export const showNotification = (type, message, description, duration = 3) => {
  notification[type]({
    message,
    description,
    duration,
  });
};

export const formatTimestamp = (timestamp, format) => dayjs(timestamp).format(format);

export const getSoundDataRecordColumns = (dispatch, setOpenDrawer, includeActions = true) => {
  const columns = [
    { 
      title: 'Licence',
      dataIndex: 'license',
      align: 'center',
    },
    { title: 'Serial',
      dataIndex: 'serial',
      align: 'center',
    },
    { 
      title: 'Date',
      dataIndex: 'date',
      align: 'center',
    },
    { 
      title: 'Nom de fichier',
      dataIndex: 'filename', 
      align: 'center',
    },
  ];
  if (includeActions) {
    columns.push({
      title: 'Infos',
      dataIndex: 'actions',
      align: 'center',
      render: (_, record) => 
        <Button 
          icon={<InfoCircleOutlined />} 
          onClick={() => {
            fetchSoundRecordDataById({ dispatch, id: record.id });
            setOpenDrawer(true);
          }}
        />,
    });
  }
  return columns;
};

export const soundRecordDataSource = (soundRecordData) => soundRecordData?.map((record) => ({
  key: record.id,
  id: record.id,
  license: record.license,
  serial: record.serial,
  date: formatTimestamp(record.timestamp, dayMonthYearTimeFormat),
  filename: record.filename,
}));

export const dataUnit = {
  vbatt: ' mV',
  temperature: ' °C',
  relativeHumidity: ' %',
  rssi: ' db',
};

export const dataKey = {
  firmware: 'version du firmware',
  btldrFirmware: 'version du bootloader',
  vbatt: 'tension',
  temperature: 'temperature',
  relativeHumidity: 'hygrométrie',
  flashStatus: 'état du composant flash',
  sdStatus: 'état du composant SD card',
  iicSensorStatus: 'état du composant capteur',
  rssi: 'niveau de réception du modem en db',
  provider: 'opérateur',
  gsmBand: 'techno',
  status: 'état de la capture',
};

export const getUniqueList= (data) => [...new Set(data?.map(item => item.serial))];

export const generateSelectOptions = (options) => options.map(option => ({ label: option, value: option }));

export const isEmpty = (value) => value === null || value === undefined || value === '';

export const checkStatus = (chartData) => {
  const hasZeroStatus = chartData.some(item => item.sensorStatus === 0);
  const hasNegativeStatus = chartData.some(item => item.sensorStatus < 0);
  return { hasZeroStatus, hasNegativeStatus };
};



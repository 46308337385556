import { fetchData } from './utils';

export const login = ({ dispatch }) => {
  dispatch({ type: 'LOGIN_SUCCESS' });
};

export const fetchSoundRecordData = ({ dispatch }) => 
  fetchData({ 
    dispatch, 
    endpoint: 'soundRecordData',
    type: 'SOUND_RECORD_DATA',
  });

export const fetchSoundRecordDataById = ({ dispatch, id }) => 
  fetchData({ 
    dispatch, 
    endpoint: `soundRecordData/${id}`,
    type: 'SOUND_RECORD_DATA_BY_ID',
  });

export const fetchSoundRecordDataBySerial = ({ dispatch, serial }) => 
  fetchData({ 
    dispatch, 
    endpoint: `soundRecordData/serial/${serial}`, 
    type: 'SOUND_RECORD_DATA_BY_SERIAL',
  });

export const fetchSoundRecordDataByRangeAndSerial = ({ dispatch, startDate, endDate, serial }) => 
  fetchData({ 
    dispatch, 
    endpoint: `soundRecordData/range/${startDate}/${endDate}/serial/${serial}`,
    type: 'SOUND_RECORD_DATA_BY_RANGE_AND_SERIAL',
  });

export const fetchSoundRecordDataByRange = ({ dispatch, start, end }) => 
  fetchData({ 
    dispatch, 
    endpoint: `soundRecordData/range/${start}/${end}`, 
    type: 'SOUND_RECORD_DATA_BY_RANGE',
  });
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { routes } from './utils/utils';
import { AppProvider } from './utils/contexts/AppContext';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import { Layout } from 'antd';

const App = () => (
  <Router>
    <Routes>
      {routes.map((route) => {
        const { id, exact, path, isPublic, title, Component } = route;
        const RouteWrapper = isPublic ? PublicRoute : PrivateRoute;
        return (
          <Route
            key={id}
            exact={exact}
            path={path}
            element={(
              <RouteWrapper title={title}>
                <Component />
              </RouteWrapper>
            )}
          />
        );
      })}
    </Routes>
  </Router>
);

const AppWrapper = () => (
  <AppProvider>
    <Layout>
      <App />
    </Layout>
  </AppProvider>
);

export default AppWrapper;
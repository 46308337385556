import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';

const SkeletonList = ({ rows = 4 }) => (

  <Skeleton className='skeletonList' active paragraph={{ rows }} />
);

SkeletonList.propTypes = {
  rows: PropTypes.number,
};

export default SkeletonList;

import React, { useState } from 'react';
import FormDetails from './FormDetails';
import { getSoundDataRecordColumns, soundRecordDataSource } from '../../utils/utils';
import { useAppContext } from '../../utils/contexts/AppContext';
import SoundRecordDataList from '../shared/lists/SoundRecordDataList';
import SkeletonList from '../shared/skeletons/SkeletonList';
import Charts from './Charts';

const Details = () => {
  const [currentRangeDate, setCurrentRangeDate] = useState(null);
  const { state, dispatch } = useAppContext();
  const { data, loading } = state.soundRecordByRangeAndSerial;
  const serialCurrentInfo = data?.[0]?.serial;

  const dataSource = soundRecordDataSource(data);
  const columns = getSoundDataRecordColumns(dispatch, null, false);

  const titleList = `Liste des enregistrements pour le serial ${serialCurrentInfo}${
    currentRangeDate?.startDate ? ` du ${currentRangeDate.startDate}` : ''
  }${currentRangeDate?.endDate ? ` au ${currentRangeDate.endDate}` : ''}`;


  return (
    <>
      <FormDetails setCurrentRangeDate={setCurrentRangeDate} />
      {loading ? (
        <SkeletonList />
      ) : (
        data && (
          <>
            <SoundRecordDataList
              title={titleList}
              dataSource={dataSource}
              columns={columns}
            />
            <Charts data={data} />
          </>
        )
      )}
    </>
  );
};

export default Details;

import React from 'react';
import PropTypes from 'prop-types';
import CardInfo from './CardInfo';

const CardsInfo = ({ currentSoundRecordInfos }) => {
  const { system, modem, sensor } = currentSoundRecordInfos.data;

  const cards = [
    { title: 'Système', data: system},
    { title: 'Modem', data: modem },
    { title: 'Capteur', data: sensor },
  ];

  return (
    <>
      {cards.map((card) => (
        <CardInfo key={card.title} title={card.title} data={card.data} />
      ))}
    </>
  );
};

CardsInfo.propTypes = {
  currentSoundRecordInfos: PropTypes.object.isRequired,
};


export default CardsInfo;

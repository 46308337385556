import React from 'react';
import PropTypes from 'prop-types';
import CardTemplate from '../../../../shared/cards/CardTemplate';
import { dataKey, dataUnit, isEmpty } from '../../../../../utils/utils';

const CardInfo = ({ title, data }) => (
  <CardTemplate className='m-large' title={title}>
    {Object.entries(data).map(([key, value]) => (
      <p key={key}>
        <strong>{dataKey[key] || key} : </strong> {isEmpty(value) ? 'N/A' : value}
        {dataUnit[key] && <span>{dataUnit[key]}</span>}
      </p>
    ))}
  </CardTemplate>
);

CardInfo.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default CardInfo;
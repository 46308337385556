import React from 'react';
import LineChartTemplate from '../shared/charts/LineChartTemplate';
import { greenLightColor, redColor } from '../../assets/styles/colors';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import { checkStatus, dayMonthYearTimeFormat } from '../../utils/utils';
import dayjs from 'dayjs';
import { baseLineCharts, charts  } from './chartsConfig';

const Charts = ({ data }) => {
  let chartData = data?.map((item) => ({
    date: dayjs(item.timestamp).format(dayMonthYearTimeFormat),
    temperature: item.data.system.temperature,
    vbatt: item.data.system.vbatt,
    humidity: item.data.system.relativeHumidity,
    rssi: item.data.modem.rssi,
    sensorStatus: item.data.sensor.status,
    flashStatus: item.data.system.flashStatus,
    iicSensorStatus: item.data.system.iicSensorStatus,
    sdStatus: item.data.system.sdStatus,
  }));

  chartData = chartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  const sensorStatusLines = () => {
    const { hasZeroStatus, hasNegativeStatus } = checkStatus(chartData);
    const lines = [];
    if (hasZeroStatus) {
      lines.push({
        dataKey: 'sensorStatus',
        strokeColor: greenLightColor,
      });
    }
    if (hasNegativeStatus) {
      lines.push({
        dataKey: 'sensorStatus',
        strokeColor: redColor,
      });
    }
    return lines;
  };

  const lineCharts  = baseLineCharts.map(chart => {
    if (chart.title === 'Status des capteurs') {
      return {
        ...chart,
        lines: sensorStatusLines(),
      };
    }
    return chart;
  });

  const renderLineCharts = () => lineCharts.map((chart) => (
    <LineChartTemplate
      key={chart.title}
      width={300}
      height={200}
      data={chartData}
      lines={chart.lines}
      title={chart.title}
      yAxisDomain={chart.yAxisDomain}
    />
  ));

  const renderCharts = () => charts.map((chart) => (
    <chart.component
      key={chart.title}
      data={chartData}
      dataKey={chart.dataKey}
      title={chart.title}
      width={chart.width}
      height={chart.height}
      fill={chart.fill}
      scatters={chart.scatters}
      positiveColor={chart.positiveColor}
      negativeColor={chart.negativeColor}
      stop={chart.stop}
    />
  ));

  return (
    <>
      <Row className='m-large' justify="space-around">
        {renderLineCharts()}
      </Row>
      <Row justify="space-around">
        {renderCharts()}
      </Row>
    </>
  );
};

Charts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Charts;